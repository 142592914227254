var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeadersDocumentation,"items":_vm.items,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.addRow}},[_vm._v(" + ")])],1)]},proxy:true},{key:"item.pos",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.posRechner(index))+" ")]}},{key:"item.benennung",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":"","return-value":item.benennung},on:{"update:returnValue":function($event){return _vm.$set(item, "benennung", $event)},"update:return-value":function($event){return _vm.$set(item, "benennung", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Benennung","single-line":""},model:{value:(item.benennung),callback:function ($$v) {_vm.$set(item, "benennung", $$v)},expression:"item.benennung"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.benennung)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.std",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":"","return-value":item.std},on:{"update:returnValue":function($event){return _vm.$set(item, "std", $event)},"update:return-value":function($event){return _vm.$set(item, "std", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Std","single-line":""},model:{value:(item.std),callback:function ($$v) {_vm.$set(item, "std", $$v)},expression:"item.std"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.std)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.preis",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":"","return-value":item.preis},on:{"update:returnValue":function($event){return _vm.$set(item, "preis", $event)},"update:return-value":function($event){return _vm.$set(item, "preis", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit Preis","single-line":"","type":"number"},model:{value:(item.preis),callback:function ($$v) {_vm.$set(item, "preis", $$v)},expression:"item.preis"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.preis))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red lighten-2"},on:{"click":function($event){return _vm.deleteRow(item)}}},[_vm._v(" mdi-delete-outline ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"black--text"},[_c('th',{staticClass:"title text-right",attrs:{"colspan":"3"}},[_vm._v("Gesamt")]),_c('th',{staticClass:"title text-right"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.sumField())))]),_c('th',{staticClass:"title text-right"})])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }