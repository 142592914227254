







































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {Dokumentation} from "@/models/Dokumentation";

@Component({
  components: {
  }
})
export default class DokumentationTableComponent extends Vue {

  public pos: number = 3;
  public tableHeadersDocumentation = [
    {
      text: 'Pos.',
      value: 'pos',
      width: '10%',
      align: 'center'
    },
    {
      text: 'Benennung',
      value: 'benennung',
      width: '45%',
      align: 'left'
    },
    {
      text: 'Std.',
      value: 'std',
      width: '15%',
      align: 'center'
    },
    {
      text: 'Preis',
      value: 'preis',
      width: '20%',
      align: 'right'
    },
    {
      text: '',
      value: 'actions',
      width: '10%',
      align: 'center'
    },


  ];

  private defaultItem: any = {
    benennung: 'platzhalter....',
    std: 1,
    preis: 1,

  }

  public items: Dokumentation[] = [
  ]

  @Watch('items', {deep:true})
  public updateOfferMachines() {
    this.$emit('junus', this.items)

  }

  public posRechner(index: any) {
    let m = (index === 0) ? 0.1 : (index+1)/10;
    return this.pos + m
  }

  public addRow() {
    this.items.push(new Dokumentation(this.defaultItem));
  }

  public deleteRow(item: any) {
    this.items.splice(this.items.indexOf(item), 1)
  }

  public sumField() {
    return this.items.reduce((a:number, b: any) => a + (parseFloat(b.preis) || 0), 0)
  }
}
